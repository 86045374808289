import React from "react"
import Layout from "../components/layout"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import MetRezidentas from "../components/metRezidentas"
import MetSpecialistas from "../components/metSpecialistas"
import MetProfesionalas from "../components/metProfesionalas"
import PriceListMet from "../components/priceListMet"
import { Link } from "gatsby";
import { withTrans } from '../i18n/withTrans'

const kainodaraMetinis = ({ children, t, i18n }) => {
  return (
    <>
        <header>
          <div className="row">
            <div className="price_width col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-11">
              <h1>{t('pricelist.servicePrices')}</h1>
            </div>
            <div className="q_width col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
              <div className="qna">
                <p className="desc_contents">?</p>
                <span className="desc_hide">{t('pricelist.qnaButton')}</span>
              </div>
            </div>
          </div>
        </header>
        <br></br>
        <br></br>
        <section className="container_toggle ">
          <div className="row ">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <h5>{t('pricelist.paymentType')}</h5>
            </div>
            {/*<div class="custom-switch col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <div class="form-check form-switch ">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"></input>
                    </div>
                    </div>*/}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-xs-12 d-flex justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-center">
            <Link 
              className="button_price_monthly price_off" 
              to="/kainodara">
                {t('pricelist.monthly')}
              </Link>
              
              <Link 
              className="button_price_yearly price_on" 
              to="/kainodaraMetinis">
                {t('pricelist.yearly')}
                </Link>
            </div>
          </div>
        </section>

        <section id="year1_prices" className="prices d-none d-md-block">
          <div className="row">
            <MetRezidentas></MetRezidentas>
            <MetSpecialistas></MetSpecialistas>
            <MetProfesionalas></MetProfesionalas>
          </div>
        </section>
        <PriceListMet></PriceListMet>
    </>
  )
}
export default withTrans(kainodaraMetinis)
