import React from "react"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import { withTrans } from '../i18n/withTrans'

const MetSpecialistas = ({ children, t, i18n }) => {
  return (
    <>
      <div className="price_bg col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <h5>{t('pricelist.professional')}</h5>
        <h1 className="sub-transformation">
          375€<sub>{t('pricelist.month')}</sub>
        </h1>
        <ul className="un_list">
          <li>{t('pricelist.convProfessional')}</li>
          <li className="desc_content"><span>{t('pricelist.convAdditional')}</span></li>
            <span className="desc_hide">{t('descComments.descConv')}</span>
          <li className="desc_content"><span>{t('pricelist.premadeTemplates')}</span></li>
            <span className="desc_hide">{t('descComments.descPremadeTemplates')}</span>
        </ul>
        

        <p>
          <b>{t('pricelist.functions')}</b>
        </p>
        <ul className="check_list">
          <li className="desc_content"><span>{t('pricelist.tablesProfessional')}</span></li>
            <span className="desc_hide">{t('descComments.descTables')}</span>
          <li className="desc_content"><span>{t('pricelist.nlp')}</span></li>
            <span className="desc_hide">{t('descComments.descNlp')}</span>
          <li className="desc_content"><span>{t('pricelist.chatbotMenu')}</span></li>
            <span className="desc_hide">{t('descComments.descChatbotMenu')}</span>  
          <li className="desc_content"><span>{t('pricelist.welcomeMessage')}</span></li>
            <span className="desc_hide">{t('descComments.descWelcomeMessage')}</span>
          <li className="desc_content"><span>{t('pricelist.defResponse')}</span></li>
            <span className="desc_hide">{t('descComments.descDefResponse')}</span>
          <li className="desc_content"><span>{t('pricelist.chatbotBuilder')}</span></li>
            <span className="desc_hide">{t('descComments.descChatbotBuilder')}</span>
          <li className="desc_content"><span>{t('pricelist.ConPanel')}</span></li>
            <span span className="desc_hide">{t('descComments.descConPanel')}</span>
        </ul>
        <p>
          <b>{t('pricelist.chatWidget')}</b>
        </p>
        <ul className="check_list">
          <li className="desc_content"><span>{t('pricelist.urlMessage')}</span></li>
            <span className="desc_hide">{t('descComments.descUrlMessage')}</span>
          <li className="desc_content"><span>{t('pricelist.widgetStyles2')}</span></li>
            <span className="desc_hide">{t('descComments.descWidgetStyles')}</span>
          <li className="desc_content"><span>{t('pricelist.conRating')}</span></li>
            <span className="desc_hide">{t('descComments.descConRating')}</span>
        </ul>


        <p>
          <b>{t('pricelist.analyticsDashboard')}</b>
        </p>
        <ul className="check_list">
          <li className="desc_content"><span>{t('pricelist.conStatistics')}</span></li>
            <span className="desc_hide">{t('descComments.descConStatistics')}</span>
          <li className="desc_content"><span>{t('pricelist.perStatistics')}</span></li>
            <span className="desc_hide">{t('descComments.descPerStatistics')}</span>
          <li className="desc_content"><span>{t('pricelist.channelStatistics')}</span></li>
            <span className="desc_hide">{t('descComments.descChannelStatistics')}</span>
          <li className="desc_content"><span>{t('pricelist.agentsStatistics')}</span></li>
            <span className="desc_hide">{t('descComments.descAgentsStatistics')}</span>
          <li className="desc_content"><span>{t('pricelist.topicsTendencies')}</span></li>
            <span className="desc_hide">{t('descComments.descTopicsTendencies')}</span>
          <li className="desc_content"><span>{t('pricelist.ratingStatistics')}</span></li>
            <span className="desc_hide">{t('descComments.descRatingStatistics')}</span>
        </ul>


        <p>
          <b>{t('pricelist.integrations')}</b>
        </p>
        <ul className="check_list">
          <li>Facebook / Messenger</li>
          <li>Instagram</li>
          <li className="desc_content"><span>{t('pricelist.visitPlatforms')}</span></li>
            <span className="desc_hide">{t('descComments.descVisitPlatforms')}</span>
        </ul>

      </div>
    </>
  )
}

export default withTrans(MetSpecialistas)
