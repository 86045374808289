import React from "react"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import MetRezidentas from "./metRezidentas"
import MetSpecialistas from "./metSpecialistas"
import MetProfesionalas from "./metProfesionalas"
import { withTrans } from '../i18n/withTrans'

const PriceListMet = ({ children, t, i18n }) => {
  return (
    <>
      <section className="d-md-none">
        <div className="accordion accordion-flush" id="accordionPricelist">
          <div className="accordion-item blue_bg">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <h5>{t('pricelist.starter')}</h5>
                <h1 className="sub-transformation">
                  250€<sub>{t('pricelist.month')}</sub>
                </h1>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <MetRezidentas></MetRezidentas>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header blue_bg" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <h5>{t('pricelist.professional')}</h5>
                <h1 className="sub-transformation">
                  375€<sub>{t('pricelist.month')}</sub>
                </h1>
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <MetSpecialistas></MetSpecialistas>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header blue_bg" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                <h5>{t('pricelist.enterprise')}</h5>
                <h1 className="sub-transformation">
                  625€<sub>{t('pricelist.month')}</sub>
                </h1>
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <MetProfesionalas></MetProfesionalas>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withTrans(PriceListMet)
